import card1 from '../images/portfolio-images/pi1.jpg';
import card2 from '../images/portfolio-images/pi2.jpg';
import card3 from '../images/portfolio-images/pi3.jpg';
import card4 from '../images/portfolio-images/pi4.jpg';
import card5 from '../images/portfolio-images/pi5.jpg';
import card6 from '../images/portfolio-images/pi6.jpg';
import card7 from '../images/portfolio-images/pi7.jpg';
import card8 from '../images/portfolio-images/pi8.jpg';
import card9 from '../images/portfolio-images/pi9.jpg';
import card10 from '../images/portfolio-images/pi10.jpg';

const cards = [
    {link: card1,
     name: 'Парикмахерские услуги',
     id: 1},
    {link: card2,
     name: 'Парикмахерские услуги',
     id: 2},
    {link: card3,
     name: 'Ногтевой сервис',
     id: 3},
    {link: card4,
     name: 'Парикмахерские услуги',
     id: 4},
    {link: card5,
     name: 'Парикмахерские услуги',
     id: 5},
    {link: card6,
     name: 'Ногтевой сервис',
     id: 6},
    {link: card7,
     name: 'Парикмахерские услуги',
     id: 7},
    {link: card8,
     name: 'Другие услуги',
     id: 8},
    {link: card9,
     name: 'Ногтевой сервис',
     id: 9},
    {link: card10,
     name: 'Другие услуги',
     id: 10}
]

export default cards;